import './App.css';
import './css/font-awesome.min.css';
import HomePage from './pages/HomePage';

function App() {


  return (
    <div className="App">
      {/* <HomePage/> */}
    </div>
  );
}

export default App;